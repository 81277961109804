/* Provide sufficient contrast against white background */
body {
    padding: 50px;
    font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
}


#info {
    position: absolute;
    width: 100%;
    color: #ffffff;
    padding: 5px;
    font-family: Monospace;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

#menu {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.lessons {
    width: 120px;
    height: 160px;
    box-shadow: 0px 0px 12px rgba(0,255,255,0.5);
    border: 1px solid rgb(76, 255, 0);
    text-align: center;
    cursor: default;
}

    .lessons:hover {
        box-shadow: 0px 0px 12px rgba(0,255,255,0.75);
        border: 1px solid rgba(127,255,255,0.75);
    }

    .lessons .lesson_number {
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        font-size: 60px;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(0,255,255,0.95);
    }

    .lessons .details {
        position: absolute;
        bottom: 15px;
        left: 0px;
        right: 0px;
        font-size: 12px;
        color: #ffd800;
    }



.element {
    width: 120px;
    height: 160px;
    box-shadow: 0px 0px 12px rgba(0,255,255,0.5);
    border: 1px solid rgba(127,255,255,0.25);
    font-family: Helvetica, sans-serif;
    text-align: center;
    line-height: normal;
    cursor: default;
}

    .element:hover {
        box-shadow: 0px 0px 12px rgba(0,255,255,0.75);
        border: 1px solid rgba(127,255,255,0.75);
    }

    .element .number {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 12px;
        color: rgba(127,255,255,0.75);
    }

    .element .symbol {
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        font-size: 60px;
        font-weight: bold;
        color: rgba(255,255,255,0.75);
        text-shadow: 0 0 10px rgba(0,255,255,0.95);
    }

    .element .details {
        position: absolute;
        bottom: 15px;
        left: 0px;
        right: 0px;
        font-size: 12px;
        color: rgba(127,255,255,0.75);
    }

button {
    color: rgba(127,255,255,0.75);
    background: rgba(0,255,255,0.2);
    outline: 1px solid rgba(127,255,255,0.75);
    border: 0px;
    padding: 5px 10px;
    cursor: pointer;
}

    button:hover {
        background-color: rgba(0,255,255,0.5);
    }

    button:active {
        color: #000000;
        background-color: rgba(0,255,255,0.75);
    }

input {
    color: #FFCB9A;
    background: transparent;
    outline: 0px solid rgba(51,255,51,0.5);
    margin-left: 10px;
    margin-right: 10px;
    border: 0px;
    padding: 5px 10px;
    cursor: pointer;
}

    input:hover {
        background-color: #000000;
    }

    input:active {
        color: #1f1f1f;
        background-color: #000000;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
        -webkit-text-fill-color: #FFCB9A !important;
    }



a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
